import React, { useState } from "react";
import { Layout } from 'antd';
import AppHeader from "./content/AppHeader";
import AppBanner from "./content/AppBanner";
import Categories from "./components/Categories";
import './style.css';
import ProductListDestacado from "./components/Products/ProductListDestacado";
import ProductListUltimo from "./components/Products/ProductListUltimo";
import Location from "./components/Location";
const { Content } = Layout;

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  window.addEventListener('resize', () => {
    setIsMobile(window.innerWidth <= 768);
  });
  return (
    <Layout>
    <AppHeader isMobile={isMobile} />
    <Content style={{ marginTop: 64 }}> {/* Margen para no tapar el contenido */}
      <AppBanner isMobile={isMobile} />
    </Content>
    <Content style={{ padding: '24px' }}>
      <Categories />
    </Content>
    <Content style={{ backgroundColor:'white' }}>
      <ProductListDestacado isMobile={isMobile} />
    </Content>
    <Content style={{ backgroundColor:'white', padding: '24px'}}>
    <Location isMobile={isMobile} />
    </Content>
    <Content style={{ backgroundColor:'white', padding: '24px'}}>
      <ProductListUltimo isMobile={isMobile}/>
    </Content>

  </Layout>
  );
}

export default App;
