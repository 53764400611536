import { Carousel } from "antd";
import imgb1 from '../assets/img/banner/racsolanding1.jpg';
import imgb2 from '../assets/img/banner/racsolanding2.jpg';
import imgb3 from '../assets/img/banner/racsolanding3.jpg';
import imgm1 from '../assets/img/banner/racsomovil1.jpg';
import imgm2 from '../assets/img/banner/racsomovil2.jpg';
import imgm3 from '../assets/img/banner/racsomovil3.jpg';
const AppBanner = ( { isMobile } ) => {

    const laptopImages = [
        imgb1,
        imgb2,
        imgb3,
      ];
    
      const mobileImages = [
        imgm1,
        imgm2,
        imgm3,
      ];
    
      const images = isMobile ? mobileImages : laptopImages;
    
      return (
        <Carousel arrows autoplay>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`slide-${index}`} style={{ width: '100%' }} />
            </div>
          ))}
        </Carousel>
      );
};
export default AppBanner;
