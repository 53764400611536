import React from 'react';
import {  Row, Col, Card, Space, Typography, Button } from 'antd';

import img1 from '../../assets/img/products/20240827_163130.jpg';
import img2 from '../../assets/img/products/20240827_163258.jpg';
import img3 from '../../assets/img/products/20240827_163417.jpg';
import img4 from '../../assets/img/products/20240827_163510.jpg';
import img5 from '../../assets/img/products/20240827_164028.jpg';
import {  ShoppingCartOutlined } from '@ant-design/icons';
// import ProductCard from './ProductCard';

const { Meta } = Card;
const { Text, Title } = Typography;

const products = [
  { id: 1, img: img1, title: 'Producto 1 Producto 1 Producto 1 Producto 1 ', description: 'Descripción 1', price: '$90000' },
  { id: 2, img: img2, title: 'Producto 2', description: 'Descripción 2', price: '$20000' },
  { id: 3, img: img3, title: 'Producto 3', description: 'Descripción 3', price: '$30000' },
  { id: 4, img: img4, title: 'Producto 4', description: 'Descripción 4', price: '$40000' },
  { id: 5, img: img5, title: 'Producto 5', description: 'Descripción 5', price: '$50000' },
  
];

const ProductListUltimo = ({ isMobile } ) => {
    const imageSize = isMobile ? 260 : 320;
    const padM = isMobile ? '0px' : '20px';
  return (
    <>
    <Title level={3} style={{ textAlign: 'center' }}>
    Ultimas Unidades
  </Title>
    <Row gutter={[8, 8]} justify="center" style={{ marginLeft: isMobile?'0':'100px', marginRight:isMobile?'0px':'100px' }} >
      {products.map(product => (
        <Col
          key={product.id}
          xs={12} // 2 cards por fila en dispositivos móviles
          sm={12}
          md={6} // 4 cards por fila en laptop
          lg={6}
          xl={6}
        >
          <Card
            hoverable
            cover={<div style={{ justifyContent: 'center', alignItems: 'center', height: imageSize }}><img alt={product.title} style={{ maxWidth: '100%', maxHeight: '100%', padding: padM }}src={product.img} className="hover-image-dest" /></div>}
            style={{ border: 'none', borderRadius: '8px', boxShadow: 'none'}}
          >
            <Meta title={product.title} description={<Space style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} >
            <Text type="success" strong>{product.price}</Text>
            <Button><ShoppingCartOutlined style={{ color: 'blue', fontSize: '14' }} /></Button>
        </Space>} style={{ marginTop:isMobile?'-80px':'0px'}} />
            
          </Card>
        </Col>
      ))}
    </Row>
    </>
  );
};

export default ProductListUltimo;