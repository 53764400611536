import React from 'react';
import { Carousel, Typography } from 'antd';
import img1 from '../../assets/img/products/20240827_163130.jpg';
import img2 from '../../assets/img/products/20240827_163258.jpg';
import img3 from '../../assets/img/products/20240827_163417.jpg';
import img4 from '../../assets/img/products/20240827_163510.jpg';
import img5 from '../../assets/img/products/20240827_164028.jpg';

import ProductCard from './ProductCard';


const { Title } = Typography;
const data = [
  {
    img: img1,
    price: '$20',
    description: 'Description 1',
    title: 'Marc Bolan Con Video'
  },
  {
    img: img2,
    price: '$30',
    description: 'Description 2',
    title: 'Marc Bolan Con Video'
  },
  {
    img: img3,
    price: '$30',
    description: 'Description 2',
    title: 'Marc Bolan Con Video'
  },
  {
    img: img4,
    price: '$30',
    description: 'Description 2',
    title: 'Marc Bolan Con Video'
  },
  {
    img: img5,
    price: '$30',
    description: 'Description 2',
    title: 'Marc Bolan Con Video'
  },
  // Agrega más elementos según sea necesario
];

const ProductListDestacado = ({ isMobile }) => {


    return (
        <>
        <Title level={3} style={{ textAlign: 'center' }}>
        Recien LLegado
      </Title>
        <div style={{ marginLeft: isMobile ? 0 : 'auto', marginRight: isMobile ? 0 : 'auto', maxWidth: isMobile ? '100%' : '80%' }}>
        <Carousel
        arrows 
          slidesToShow={4} 
          slidesToScroll={1} 
          responsive={[
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
          className="custom-carousel" 
        >
          {data.map((item, index) => (
            
            <ProductCard index={index} item={item} isMobile={isMobile} />
            
          ))}
        </Carousel>
        <style jsx>{`
        .custom-carousel .slick-prev,
        .custom-carousel .slick-next {
          color: #000; /* Cambia el color de las flechas a negro */
        }

        .custom-carousel .slick-prev:before,
        .custom-carousel .slick-next:before {
          color: #000; /* Cambia el color de los iconos de flechas a negro */
        }
      `}</style>
        </div>
        </>
      );
}
export default ProductListDestacado;