import React from 'react';
import { Card, Row, Col } from 'antd';

import imgTienda from '../../assets/img/pages/TiendaDisco.jpg';
import imgTiendaMovil from '../../assets/img/pages/TiendaDiscoMovil.jpg';
const { Meta } = Card;

const Location = ({isMobile}) => {
  const imageSize = isMobile?'300px':'650px';
  const srcImage = isMobile? imgTiendaMovil:imgTienda;
  const marginLefMobil = isMobile?'0px':'-100px';
  const marginTopMobil = isMobile?'-50px':'0px';
return (<Row
     // Espaciado entre las columnas
    style={{ marginLeft: isMobile?'0px':'100px', marginRight:isMobile?'0px':'100px' }}
  >
    <Col
     xs={24} // 100% en móviles (una sola columna)
     md={17} // 75% en laptops
      style={{ display: 'flex', justifyContent: 'center' }} // Centrar contenido en la columna
    >
      <img
        src={srcImage} // Reemplaza con la URL de tu imagen
        alt="Descripción de la imagen"
        style={{ maxWidth: imageSize, height: 'auto', display:'block' }} // Imagen responsiva
      />
    </Col>
    <Col
     xs={0} // 100% en móviles
     md={7}
     
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft:marginLefMobil, marginTop:marginTopMobil}} // Centrar el Card en la columna
    >
      <Card
        
        hoverable
        style={{
          boxShadow: '-10px 0px 20px rgba(0, 0, 0, 0.3)', // Sombra a la izquierda
        }}
      >
        <Meta
          title="NOS UBICAMOS"
          description="Persa BIO BIO  Victor Manuel 2220 local 127, Santiago, Región Metropolitano. También pueden retirar los discos a la espalda de Plaza de Armas."
        />
      </Card>
    </Col>
  </Row>);
};
export default Location;