import React from 'react';
import { PlusOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Button, Card, Space, Typography } from "antd";
const { Meta } = Card;
const { Text } = Typography;
const ProductCard = ({ index, item, isMobile }) => {
    const imageSize = isMobile ? 260 : 320;
    const marginSize = isMobile ? '10px' : '15px';
    const padM = isMobile ? '0px' : '20px';
    return (
        <div key={index} style={{ padding: marginSize }}>
    <Card
        key={index}
        style={{ border: 'none', borderRadius: '8px', boxShadow: 'none' }}
        cover={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: imageSize }}>
            <img style={{ maxWidth: '100%', maxHeight: '100%', padding: padM }} alt="example" src={item.img} className="hover-image-dest" />
        </div>}
    >
        <Meta title={item.title} description={<Space style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Text type="success" strong>$20</Text>
            <Button><PlusOutlined style={{ color: 'blue', fontSize: '14' }} /><ShoppingCartOutlined style={{ color: 'blue', fontSize: '14' }} /></Button>
        </Space>} />
    </Card></div>);
};
export default ProductCard;
