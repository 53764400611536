import React, { useState } from 'react';
import { Layout, Menu, Input, Drawer, Space } from 'antd';
import { CloseOutlined, MenuOutlined, SearchOutlined, ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';

import racso  from '../assets/img/logo/racso.svg';
import racsoMovil from '../assets/img/pages/fondoCelularRacso.jpg';

const { Search } = Input;
const { Header } = Layout;

const AppHeader = ({isMobile}) => {
  console.log('RenderAppHeader');
  const [flagSearch, setFlagSearch] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  
  const menuItems = [
    {
      key: '1',
      label: 'Inicio',
    },
    {
      key: '2',
      label: 'Catalogo',
    },
    {
      key: '3',
      label: 'Contacto',
    },
  ];

  const onSearch = (value, _e, info) => console.log(info?.source, value);

  const menuMovilItems = [
    {
      key: '1',
      label: 'Inicio',
    },
    {
      key: '2',
      label: 'Catalogo',
    },
    {
      key: '3',
      label: 'Contacto',
    },
    {
      key: '4',
      label: 'Entrar Cuenta',
      icon : <UserOutlined />
    },
  ];


  return (
    <Header className="app-header">
      <div className="header-left">
        {isMobile && (
          <MenuOutlined
            className="menu-icon"
            onClick={() => setDrawerVisible(true)}
          />
        )}
        
        {!isMobile && (
          <Space >
            <img src={racso} alt="Logo" className="logo logo-top" />
            <Menu mode="horizontal" theme="dark" className='header-menu' items={menuItems}/>
              
            <Search
            placeholder="¿Qué estás buscando?"
            allowClear
            onSearch={onSearch}
          className='header-search'
          />
        </Space>
        )}
      </div>

      {isMobile && (  
        <div className="header-center">
          {!flagSearch && (<img src={racso} alt="Logo" className="logo" />)}
          {flagSearch && (<Search
            placeholder="¿Qué estás buscando?"
            allowClear
            onSearch={onSearch}
          className='header-search-movil'
          />)}
      </div>
      )}

      <div className="header-right">
      {(isMobile && !flagSearch) &&  (
          <SearchOutlined className="search-icon" onClick={()=>{setFlagSearch(true)}}/>
      )}
      {(isMobile && flagSearch) && (
        <CloseOutlined className="search-icon" onClick={()=>{setFlagSearch(false)}} />
      )}
        <ShoppingCartOutlined className="shop-icon"/>
        {!isMobile && (
            <UserOutlined className="user-icon" />
        )}
      </div>

      <Drawer
        title="Menú"
        placement="left"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        closeIcon={<span style={{ color: '#ffffff' }}>X</span>}
        style={{
          backgroundImage: `url(${racsoMovil})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          color:'#fff'
        }}
        styles={{
          padding: 0,
          body: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Menu mode="vertical" theme='dark' onClick={() => setDrawerVisible(false)} items={menuMovilItems}  
        style={{
          
          backgroundColor: 'transparent', // Fondo transparente
          border: 'none', // Sin borde
          fontSize:'16px'
          
      }}/>
      </Drawer>
    </Header>
  );
};

export default AppHeader;
