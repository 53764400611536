import { Col, Row } from "antd";
import imagen1 from '../../assets/img/pages/laserDisc.jpg';
import imagen2 from '../../assets/img/pages/vinilos.jpg';
import imagen3 from '../../assets/img/pages/cddvd.jpg';
const Categories = () => {

    return (
        <>
       <Row gutter={[16, 16]} justify="center" className="layout-row">
          {/* Columna izquierda */}
          <Col 
            xs={0} sm={0} md={2} lg={2} xl={2} 
          />

          {/* Columna central */}
          <Col 
            xs={24} sm={24} md={20} lg={20} xl={20}
          >
            <Row gutter={[16, 16]} justify="center">
              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <img
                  alt="example"
                  src={imagen1}
                  className="hover-image"
                />
              </Col>
              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <img
                  alt="example"
                  src={imagen2}
                  className="hover-image"
                />
              </Col>
              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <img
                  alt="example"
                  src={imagen3}
                  className="hover-image"
                />
              </Col>
            </Row>
          </Col>

          {/* Columna derecha */}
          <Col 
            xs={0} sm={0} md={2} lg={2} xl={2} 
          />
        </Row>
        </>
    );
}
export default Categories;